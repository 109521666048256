import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import './App.css';

function App() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '' // New field for custom message
    });

    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSubmitted(false);

        try {
            // Send the form data to the PHP backend
            const response = await axios.post('https://invoge.com/backend/submit_form.php', formData);

            // Check if the submission was successful
            if (response.status === 200) {
                setSubmitted(true); // Success, show a success message
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            console.error('Error response:', error.response);

            if (error.response) {
                setErrorMessage(`Oops! There was an error submitting the form. Error Details: ${error.response.status} - ${error.response.statusText}. Please reach us at 'contact@invoge.com'`);
            } else {
                setErrorMessage('Sorry! There was an error submitting the form. Please reach us at \'contact@invoge.com\'');
            }
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>Welcome to Invoge</h1>
                <h2>We're building something awesome</h2>
                <p>Follow this space for more</p>
            </header>

            <section className="sign-up-section">
                <h2>Sign Up for Updates</h2>
                {submitted ? (
                    <p>Thank you for signing up! We'll keep you updated.</p>
                ) : (
                    <form onSubmit={handleSubmit} className="contact-form">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Share your thoughts or questions here (optional)"
                            value={formData.message}
                            onChange={handleInputChange}
                            maxLength="200" // Limit to 200 characters
                        />
                        <button type="submit">Sign Up</button>
                    </form>
                )}
                {errorMessage && <p className="error">{errorMessage}</p>}
            </section>

            <footer className="App-footer">
                <p>&copy; 2024 Invoge. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
